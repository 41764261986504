import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { moveToEnableTotpScreen, moveToEnableSmsScreen } from 'TwoFA/helpers/common';
import Totp from './totp';
import Sms from './sms';
import withStatus from '../../hocs/with_status';

const App = ({ enabledTotp, enabledSms, recommendedMethod }) => (
  <div className="bvs-card twofa-enable">
    <div className="twofa-enable__icon" />
    <div className="bvs-h3 twofa-enable__title">
      {t('javascript.twofa.settings.enable_method.title')}
    </div>
    <div className="twofa-enable__methods">
      {enabledTotp != null && (
        <Totp enableCb={moveToEnableTotpScreen} recommended={recommendedMethod === 'totp'} />
      )}
      {enabledSms != null && (
        <Sms enableCb={moveToEnableSmsScreen} recommended={recommendedMethod === 'sms'} />
      )}
    </div>
  </div>
);

App.propTypes = {
  enabledTotp: PropTypes.bool.isRequired,
  enabledSms: PropTypes.bool.isRequired,
  recommendedMethod: PropTypes.string.isRequired,
};

export default withStatus(App);
